import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '255, 255, 255',
		'primary-dark': '110, 112, 113',
		'accent': '96, 177, 34',
		'accent-plus': '255, 255, 255',
	},
});
